.no-scroll {
    overflow: hidden;
}

/* Add this to your CSS file */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader {
    border: 8px solid rgba(255, 255, 255, 0.8); /* Mostly white with transparency */
    border-top: 8px solid orange; /* Top part is orange */
    border-radius: 50%;
    animation: spin 2s linear infinite; /* Use the spin animation */
  }


  
  






  .footer-container {
    position: relative; /* Keeps footer at the bottom */
    z-index: 1; /* Ensure it's above most content but below modals */
  }
  
  .prescription-box {
    position: relative;
    z-index: 2; /* Ensures the prescription box appears above the footer */
    overflow-y: auto;
  }


  .scrollbar-hide {
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .scrollbar-hide::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  



  