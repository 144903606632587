@tailwind base;
@tailwind components;
@tailwind utilities;
/* Add this CSS to your stylesheet */

.button-gradient {
    background: linear-gradient(90deg, #FFA500, #FFD700); /* Gradient from orange to yellow */
    border: none;
    color: white; /* Text color */
    padding: 10px 20px; /* Padding for button */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: background 0.3s; /* Smooth transition */
}

.button-gradient:hover {
    background: linear-gradient(90deg, #FFD700, #FFA500); /* Reverse gradient on hover */
}

.medicine-card {
    background: white; /* Background for the card */
    border-radius: 10px; /* Rounded corners for the card */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px; /* Padding inside the card */
    margin: 15px 0; /* Margin between cards */
    display: flex; /* Flex for layout */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
}

.medicine-card img {
    border-radius: 10px; /* Rounded corners for image */
}

.medicine-info {
    margin-left: 15px; /* Space between image and text */
}

.medicine-info input {
    margin-top: 5px; /* Space between text and input */
    padding: 5px; /* Padding for input */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid #ccc; /* Light border */
}
/* Add this to your CSS file */
.bg-cream-200 {
    background-color: #efc28a; /* A light cream color */
  }
  




  .loader {
    border: 8px solid rgba(255, 255, 255, 0.8); /* Outer ring */
    border-top: 8px solid orange; /* Top color for the spinning effect */
    border-radius: 50%;
    animation: spin 2s linear infinite; /* Animation for spinning */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}









